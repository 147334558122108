import React from "react";
import theme from "theme";
import { Theme, Link, Image, Em, Strong, Text, LinkBox, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Dropify.net for Creators Becoming Founders
			</title>
			<meta name={"description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:title"} content={"Dropify.net for Creators Becoming Founders"} />
			<meta property={"og:description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/cover.png?v=2021-12-12T04:10:19.294Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Section
			box-sizing="border-box"
			quarkly-title="Header"
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack md-justify-content="space-between">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" align-items="center" justify-content="flex-start" md-justify-content="flex-start" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/index"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem quarkly-title="Menu" md-width="25%" width="50%" display="block">
					<Override slot="StackItemContent" md-justify-content="flex-end" align-items="center" justify-content="center" />
					{"        "}
					<Components.BurgerMenu>
						<Override slot="icon,icon-close" category="md" icon={MdMenu} />
						<Override slot="icon" category="md" icon={MdMenu} size="36px" />
						<Override
							slot="menu-open"
							md-top={0}
							md-bottom={0}
							md-display="flex"
							md-flex-direction="column"
							md-align-items="center"
							md-justify-content="center"
						/>
						<Override slot="icon-open" md-position="fixed" md-top="24px" md-right="calc(4% + 4px)" />
						<Override
							slot="menu"
							md-left={0}
							md-width="100%"
							padding="0px 0 0px 0"
							lg-transition="transform 400ms ease 0s"
							display="flex"
							md-top={0}
							md-height="100%"
							lg-transform="translateY(0px) translateX(0px)"
							md-position="fixed"
						>
							<Override
								slot="item"
								text-transform="uppercase"
								text-align="center"
								padding="8px 20px 8px 20px"
								md-padding="16px 40px 16px 40px"
								display="inline-block"
							/>
							<Override slot="item-404" lg-display="none" display="none" />
							<Override slot="item-index" lg-display="none" display="none" />
							<Override
								slot="link"
								md-opacity=".5"
								md-hover-opacity="1"
								font="--base"
								color="--dark"
								md-active-opacity="1"
								opacity=".5"
								letter-spacing="0.5px"
								md-color="--dark"
								md-transition="opacity .15s ease 0s"
								transition="opacity .15s ease 0s"
								hover-opacity="1"
								md-font="16px/24px sans-serif"
								text-decoration-line="initial"
								text-transform="initial"
							/>
							<Override
								slot="link-active"
								color="--primary"
								cursor="default"
								md-opacity="1"
								md-cursor="default"
								opacity="1"
							/>
							<Override slot="link-product" display="none" />
						</Override>
					</Components.BurgerMenu>
					{"    "}
				</StackItem>
				<StackItem display="flex" quarkly-title="Side" width="25%" md-display="none">
					<Override slot="StackItemContent" align-items="center" justify-content="flex-end" />
					{"   "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				color="--dark"
				font="--headline1"
				lg-text-align="left"
				sm-font="--headline2"
				sm-text-align="left"
				md-text-align="left"
				text-align="left"
				margin="0px 0px 24px 0px"
			>
				Why Dropify?
			</Text>
			<Components.QuarklycommunityKitLoopText slides="creativity,belonging" width="100%" font="--headline2">
				<Override slot="Before Text" display="inline" font="--projectManagement" sm-font="--headline2">
					Humanity needs{" "}
					<br />
					more
				</Override>
				<Override slot="After Text" font="--projectManagement" sm-font="--headline2">
					.
				</Override>
				<Override slot="Looped Text" display="inline-block" font="--projectManagement" sm-font="--headline2" />
			</Components.QuarklycommunityKitLoopText>
			<Box
				width="100%"
				flex-direction="column"
				padding="28px 0px 16px 0px"
				md-margin="0px 0px 20px 0px"
				display="flex"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Text
					quarkly-title="Title"
					text-transform="uppercase"
					text-align="center"
					font="--base"
					opacity="0.6"
					letter-spacing="1px"
					margin="0px 0px 10px 0px"
					color="--dark"
					lg-margin="0px 0px 6px 0px"
					lg-text-align="center"
				>
					<Strong>
						Our VISION
					</Strong>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="left"
					display="flex"
					align-items="flex-start"
				>
					A creator economy driven by creators and their virtues, by supporting and creating more independent creators.
				</Text>
			</Box>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				padding="28px 0px 16px 0px"
				md-margin="0px 0px 20px 0px"
			>
				<Text
					text-align="center"
					opacity="0.6"
					letter-spacing="1px"
					margin="0px 0px 10px 0px"
					color="--dark"
					lg-margin="0px 0px 6px 0px"
					text-transform="uppercase"
					font="--base"
					lg-text-align="center"
					quarkly-title="Title"
				>
					<Strong>
						The TEAM
					</Strong>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="left"
					display="flex"
					align-items="flex-start"
				>
					The co-founding team at Dropify collectively has 15+ years working in institutions, businesses and some of the fastest growing start ups in Canada.
				</Text>
			</Box>
			<Box
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				width="100%"
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(5, 1fr)"
			>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					padding="24px 24px 0px 24px"
				>
					<Box
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						width="100%"
						height="auto"
					>
						<Image
							position="absolute"
							top={0}
							bottom={0}
							right={0}
							display="block"
							object-fit="cover"
							src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/Screenshot_20200807-152404.png?v=2021-12-11T18:57:50.962Z"
							left={0}
							width="100%"
							max-height="100%"
							lg-display="block"
							border-radius="50%"
						/>
					</Box>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px" width="100%">
						<Text
							color="--darkL1"
							margin="21px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
						>
							Matthew{" "}
							<br />
							Lal
						</Text>
						<Text
							font="--base"
							display="block"
							text-align="center"
							color="--greyD2"
							margin="16px 0px 26px 0px"
						>
							<Strong>
								Co-Founder & CEO
								<br />
							</Strong>
							{"\n\n"}
							<br />
							All things product and technology.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					padding="24px 24px 0px 24px"
					position="relative"
				>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
					>
						<Image
							display="block"
							width="100%"
							max-height="100%"
							src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/DSC_6966_edited.jpg?v=2021-12-11T18:59:24.779Z"
							left={0}
							right={0}
							top={0}
							bottom={0}
							border-radius="50%"
							object-fit="cover"
							position="absolute"
						/>
					</Box>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px" width="100%">
						<Text
							margin="21px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--darkL1"
						>
							Jonathan Hersh
							<br />
						</Text>
						<Text
							font="--base"
							display="block"
							text-align="center"
							color="--greyD2"
							margin="16px 0px 26px 0px"
						>
							<Strong>
								{"\n"}Co-Founder & COO{"\n\n"}
							</Strong>
							<br />
							<br />
							All things business and operations.
						</Text>
					</Box>
				</Box>
				<Box
					padding="24px 24px 0px 24px"
					position="relative"
					display="none"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
					>
						<Image
							object-fit="cover"
							bottom={0}
							max-height="100%"
							left={0}
							right={0}
							display="block"
							width="100%"
							border-radius="50%"
							src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/Snapchat-134014264.jpg?v=2021-12-11T19:00:45.342Z"
							position="absolute"
							top={0}
						/>
					</Box>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px" width="100%">
						<Text
							font="--headline3"
							display="block"
							text-align="center"
							color="--darkL1"
							margin="21px 0px 0px 0px"
						>
							Melissa Loewen
							<br />
						</Text>
						<Text
							font="--base"
							display="block"
							text-align="center"
							color="--greyD2"
							margin="16px 0px 26px 0px"
						>
							<Strong>
								{"\n"}Co-Founder & CPCO{"\n\n"}
							</Strong>
							<br />
							<br />
							All things people and culture.
						</Text>
					</Box>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					padding="24px 24px 0px 24px"
				>
					<Box
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
					>
						<Image
							border-radius="50%"
							src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/1143.JPG?v=2021-12-11T19:02:30.231Z"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							width="100%"
							max-height="100%"
							right={0}
							display="block"
						/>
					</Box>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px" width="100%">
						<Text
							margin="21px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--darkL1"
						>
							Nala
							<br />
						</Text>
						<Text
							font="--base"
							display="block"
							text-align="center"
							color="--greyD2"
							margin="16px 0px 26px 0px"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								{"\n"}
								<br />
								Chief Doggo
								<br />
							</Strong>
							<br />
							Because entrepreneurship is hard work!
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Text
				text-align="center"
				lg-text-align="center"
				sm-margin="0px 0px 48px 0px"
				sm-font="--headline3"
				md-font="--headline2"
				font="--headline2"
				margin="0px 0px 64px 0px"
				color="--dark"
			>
				<Strong>
					Our Values
				</Strong>
			</Text>
			<Box
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
				display="grid"
				lg-flex-wrap="wrap"
			>
				<Box sm-margin="0px 0px 30px 0px" sm-width="100%" display="flex" flex-direction="column">
					<Text
						align-items="center"
						width="49px"
						border-radius="50px"
						display="flex"
						justify-content="center"
						color="--light"
						height="49px"
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Global
					</Text>
					<Text lg-text-align="left" margin="0px 0px 0px 0px" color="--greyD2" font="--base">
						We are global citizens who think big and recognize that impact is anywhere and anyone. We believe small teams can accomplish global impact.
						<br />
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						font="--headline3"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						border-radius="50px"
						display="flex"
						height="49px"
						background="--color-indigo"
					>
						2
					</Text>
					<Text lg-text-align="left" margin="0px 0px 18px 0px" color="--darkL2" font="--headline3">
						Ownership
					</Text>
					<Text color="--greyD2" font="--base" lg-text-align="left" margin="0px 0px 0px 0px">
						We strive to raise the bar, and never settle. As owners, we are accountable for all outputs of our company.
					</Text>
				</Box>
				<Box flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%" display="flex">
					<Text
						justify-content="center"
						background="--color-indigo"
						lg-margin="0px 0px 18px 0px"
						margin="0px 0px 32px 0px"
						display="flex"
						border-radius="50px"
						align-items="center"
						color="--light"
						width="49px"
						height="49px"
						sm-margin="0px 0px 20px 0px"
						padding="7px 24px 8px 24px"
						font="--headline3"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Compassion
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						We help companies to build more inclusive teams and processes that recognize and amplify the contributions of the team.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						display="flex"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						padding="7px 24px 8px 24px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						margin="0px 0px 32px 0px"
						background="--color-indigo"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Clarity
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						We provide operational clarity inside and out by diving deep at all levels of the process.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						color="--light"
						height="49px"
						background="--color-indigo"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						display="flex"
						justify-content="center"
						padding="7px 24px 8px 24px"
						align-items="center"
						width="49px"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						5
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Curiosity
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						We believe that compassion plus curiosity leads to empathy. We seek wisdom by leaning into the future and thinking long term.
					</Text>
				</Box>
				<Box flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px" display="flex">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						color="--light"
						background="--color-indigo"
						justify-content="center"
						width="49px"
						height="49px"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						6
					</Text>
					<Text color="--darkL2" font="--headline3" lg-text-align="left" margin="0px 0px 18px 0px">
						Design-driven
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						We believe that products and processes that are designed better, perform better.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Header"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack xl-justify-content="center" justify-content="center">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" align-items="center" justify-content="flex-start" md-justify-content="flex-start" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/index"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem display="flex" quarkly-title="Logo" md-width="50%" width="50%">
					<Override slot="StackItemContent" align-items="center" justify-content="center" md-justify-content="flex-end" />
					<Link
						text-decoration-line="initial"
						opacity="0.6"
						font="--base"
						href="/terms"
						color="#000000"
					>
						Terms of Use & Privacy Policy
					</Link>
					{"        "}
				</StackItem>
				<StackItem
					md-width="100%"
					display="flex"
					quarkly-title="Side"
					width="25%"
					md-display="block"
					sm-display="block"
				>
					<Override
						slot="StackItemContent"
						align-items="center"
						justify-content="flex-end"
						flex-direction="row"
						md-justify-content="center"
						sm-justify-content="center"
					/>
					<Section padding="0px 0 0px 0" sm-padding="40px 0">
						<SocialMedia twitter="https://twitter.com/dropifyxyz" discord="https://discord.gg/ayC8tt24Cd">
							<Override
								slot="link"
								border-radius="50%"
								color="--light"
								margin="0 8px"
								background="--color-grey"
								hover-background="--color-greyD1"
							/>
						</SocialMedia>
					</Section>
					{"   "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script async={true} place={"endOfHead"} rawKey={"61b58c1050b03822215b2eac"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-MV36QFQ');"}
			</script>
			<script place={"endOfHead"} rawKey={"6246a896ffca0fbab6cc4994"}>
				{"document.addEventListener(\"DOMContentLoaded\",(e)=>{const ttclid=new URLSearchParams(window.location.search).get('ttclid');if(localStorage.getItem('ttclid')&&!ttclid){return}localStorage.setItem('ttclid',ttclid)})"}
			</script>
		</RawHtml>
	</Theme>;
});